.container {
    height: 100vh;
    width: 100%;
    background-color: #4a4a4a;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.box {
    position: relative;
    width: 350px;
    background: rgba(255, 255, 255, 0.059);
    box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.form {
    padding: 20px 30px;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #3a3d3d;
    margin-left: 10px;
    margin-right: 15px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-top: 20px;
    margin-bottom: 70px;
    padding-bottom: 70px;
    
}

.error {
    width: 100%;
    border-radius: inherit;
    background: rgba(250, 1, 1, 0.993);
    box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    padding: 15px 10px;
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
}

.form h2 {
    color: #fff;
    position: relative;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-left: 5px;
    margin-bottom: 10px;
}

.form h2::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 80%;
    height: 6px;
    border-radius: 5px;
    background: #f5f7f7;
}

.form .inputfield {
    width: 100%;
    margin-top: 40px;
}

.form .inputfield input {
    width: 100%;
    background: rgba(225, 225, 225, 0.2);
    outline: none;
    border: none;
    padding: 12px 15px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 16px;
    letter-spacing: 1px;
    color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.form .inputfield input::placeholder {
    color: #fff;
    text-transform: capitalize;

}

.form .inputfield input:focus {
    border-bottom: 4px solid#fff;
    background: transparent;
}

.form .inputfield input[type="submit"] {
    color: rgb(255, 254, 254);
    background: rgb(97, 97, 97);
    max-width: 100px;
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 300;
    cursor: pointer;
}

.forget {
    margin-top: 5px;
    color: rgb(255, 255, 255);
    font-size: 14px;
}

.forget .link {
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1px;
}
.lnh{
    color: red;
}
.lnl{
    color:red;
}