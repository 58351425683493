.abu{
    margin-top: 40px;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: brown;
}

.web{
    margin-top: 10px;
    margin-left: 5px;
}
.abu1{
    margin-top: 40px;
    margin-left: 5px;
}
.ceo{
    background-color: rgb(236, 236, 228);
    margin-top: 50px;
    margin-bottom: 100px;
    margin-left: 5px;
    margin-left: 10px;
    width: 250px;
}
.ceobr{
    margin-top: 25px;
    text-align: center;
}
.ceorb{
    margin-top: 10px;
    font-size: 25px;
    font-family: cursive;
    text-align: center;
}
.pera{
    margin-left: 15px;
    margin-right: 15px;
    text-align: justify;
}

.para{
    margin-left: 15px;
    margin-right: 15px;
    text-align: justify;
}
.rsmtr{
    margin-top: 40px;
    width: 260px;
    margin-left: 35px;
}
.directors{
    background-color: rgb(223, 219, 216);
    margin: 15px;
    padding-bottom: 50px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.dir1{
    background-color: #185666;
    margin-right: 25px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}
.dir2{
    background-color: #185666;
    margin-top: 15px;
    margin-right: 25px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.prgraph{
    font-family:serif;
    line-height: 1.6;
    font-style: oblique;
    text-align: justify;
    padding: 15px;
    color: rgb(7, 7, 7);
}