/* src/UserCourses.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.userhn {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
}

.course_head{
    background: #3e7377;
    padding: 20px;
    margin-top: 0px;
    text-align: center;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    
}


.course-card {
    border: 1px solid #ccc;
    padding: 50px;
    margin: 50px 0;
    border-radius: 5px;
}

button {
    background-color: rgb(245, 62, 6);
    color: white;
    border: none;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
}

footer {
    text-align: center;
    margin-top: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
   
   .learn{
    font-size: 25px;
    color: aliceblue;
    margin-top: 3px;
   }
    .course-card {
        padding: 10px;
    }
    h2{
        margin-top:70px;
    }
    .notice{
        color: red;
    }
    .note{
        font-size: small;
    }
}
