.container {
    padding: 10px;
    position:relative;
    
    padding-bottom: 120%;
    
}
.imagebox
{
    
    border-radius: 50%;
    background: rgba(211, 212, 212, 0.945);
   height: 140px;
   width: 150px;
   margin: 15px;
   padding: 50px;
   display: block;
   
   position: relative;
}
.icons
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   cursor: pointer;
}
.profileButton
{
    
    position:absolute;
    top: auto;
    right:auto;
    font-size: large;
    text-align: center;
    color: rgb(7, 7, 7);
    background-color: rgb(45, 95, 107);
    border-radius: 5px;
    padding:  5px;
    font-weight: bold;
    margin-top:840%;
    padding-left: 20px;
    
}


.buttonsimple
{    background-color: #f6fafa;
    margin-left:80px;
    margin-top: 50px;
    border: none;
    outline: none;
    color: rgb(245, 62, 6);
    padding:  5px;
    font-weight: bold;
    border-radius: 5px;
    
    
}
.size
{
    color: #333;
}
.deleteButton
{
    position: absolute;
    border: none;
    outline: none;
    color: rgb(255, 0, 0);
   background: #fff;
   border-radius:40px;
   padding: 8px;
   top: 5px;
   right: 5px;
   cursor: pointer;
}





.innerBoxEdit
{
    
    width: 100%;
    height:2000px;
    background: rgb(65, 64, 64);
    z-index: 10;
    position:absolute;
    overflow-y: scroll;
    margin-top: -250px;
}
.innerBox1
{
  display:    flex ;
  flex-direction: column;
  

}
.extraCss
{
    display: flex;
    flex-direction: column;
}
.inputdata
{
    text-transform: capitalize;
    background: rgba(225, 225, 225, 0.5);
    outline: none;
    border: none;
    padding: 8px 15px;
    width: 100%;
    margin: 0 5px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-right: 2px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 12px;
    letter-spacing: 1px;
    color: rgb(120, 120, 120);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}
.textarea
{
    background: rgba(225, 225, 225, 0.5);

    width: 100%;
    padding: 10px;
    outline: none;
    border: none;
}

.heading {
    
    padding-bottom: 100px;
    width: 350px;
    text-align: center;
    letter-spacing: 1px;
    margin-left: 1px;
    background-color: #c7caca;
    margin-bottom: 25px;
    border-radius: 5px;
    margin-top:auto;
    
    
}
.h2 {
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    background-color: rgb(98, 156, 153);
    margin-top: 15px;
    
    
}

.greeting {
    text-align:center;
    font-size: 15px;
    font-style: italic;
    font-variant: small-caps; 
    margin-top: 25px;  
    
    
}
.h6 {
    text-align: start;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    background-color: rgb(8, 107, 114);
    padding: 10px;
    margin-top:30%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 20px;
    color: rgb(0, 0, 0);
    margin-bottom: 10px;
}   

.innerCintainer {
    position:absolute;
    
    align-items: center;
        
        padding:5px;
        
        
        
    
}
.box1 {
    text-align: left;
    font-style: italic;
    border-radius: 25px;
    padding: 10px;
    padding-bottom: 7%;
   margin: 20%;
    
    width: 100%;
    height: max-content;
    border-top-left-radius: 15px;
    border-color: black;
    background-color: rgb(160, 175, 179);
    
}
.pro {
    
    border-radius: 50%;
    
    margin-top:30px;
    margin-bottom:auto;
    margin-left: 65px;
    width: 200px;
    height: 190px;

    
}

.innerBox
 {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    height: 100%;
}
.littleBox
{
margin: 20px 0;
text-align: left;
margin-left: 20px;    
margin-right: 15px;
margin-top: 15px;
}
.littleBox .text {
    background-color: rgb(243, 245, 245);
    padding: 2px;
    border-radius: 3px;
    font-size: smaller;
    
}
.text
{
    font-size: 2px;
    margin: 5px 0 ;
    
}
.h3
{
    font-size: 15px;
    color: rgb(40, 40, 40);
}



.verifiable
{
    display: flex;
   align-items: flex-end;
}


.buttonverify
{
    cursor: pointer;
    padding: 2px 6px;
    background: #333;
    color: #fff;
    border: none;

}
.verify
{
    font-size: 10px;
    margin-left: 5px;
    color: black;
    font-weight: bold;
}
.imageconatiner{
    width: 170px;
    background-color: #1f5156;
    margin-left: 70px;
    margin-top: 30px;
    border-radius: 20%;
}
.alfa{
    font-size: 10px;
}
.sponserr{
    text-align: start;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    background-color: rgb(8, 107, 114);
    padding: 10px;
    margin-top:80%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 20px;
    color: rgb(0, 0, 0);
    margin-bottom: 10px;
}
.hellow{
    font-size: large;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: 500;
    text-overflow: ellipsis;
    color:blueviolet;
    margin-top: 10px;
}