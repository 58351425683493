.header {
    
    display:flex;
    
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px;
    height: 80px;
    background-color: #1f5156;
 }
 
 
 .link {
    display: flex;
    align-items: center;
 }
 
 .ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    
 }
 
 .ul li {
    margin: 0 10px;
    
    
 }
 
 .menu-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    margin-top: 50px;
 }
 img {
    width: 80px; /* Resize the logo */
    height: auto; /* Maintain aspect ratio */
    margin-right: 0px; /* Space between logo and title */
    margin-top: 40px; 
}
 /* Mobile Styles */
 @media (max-width: 768px) {
    .header{
        margin-top:0px;
    }
    .learn{
      margin-top: 25px;
    }
    .link {
       display: none; /* Hide the nav links by default */
       flex-direction: column;
       position: absolute;
       top: 80px; /* Adjust based on header height */
       right: 0;
       background:rgb(77, 123, 134);
       width: 100%;
       box-shadow: 0 10px 18px rgba(223, 218, 218, 0.1);
    }
 
    .link.open {
       display: flex; /* Show links when open */
    }
 
    .menu-toggle {
       display: block; /* Show the menu toggle button */
       margin-top: 5px;
    }
 
    .ul {
       flex-direction: column; /* Stack items vertically */
       
       
    }
 
    .ul li {
       margin: 10px 0; /* Add spacing for mobile */
    }
    .logos{
        width: fit-content;
    }
    img {
        width: 80px; /* Resize the logo */
        height: auto; /* Maintain aspect ratio */
        margin-right: 0px; /* Space between logo and title */
        margin-top: 10px;
    }
   .alphat{
      color: white;
   }
 }
 