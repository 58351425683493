.cont{
    color: brown;
}
.phone{
    border-radius: 10px;
    background-color: aliceblue;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 40px;
}
.ph{
    width: 25%;
    align-items: center;
    margin-top: 25px;
    margin-right: 15px;
    padding: 20px;
    padding-left: 10px;
}
.no{
    margin-top: 10px;
    padding-left: 0px;
}
.mail{
    border-radius: 10px;
    background-color: aliceblue;
    align-items: center;
    margin-left: 0px;
    margin-right: 15px;
    padding-left: 12%;
}
.em{
    border-radius: 10px;
    width: 25%;
    align-items: center;
    margin-top: 25px;
    margin-right: 15px;
    padding: 20px;
    padding-left: 25px;
}
.add{
    border-radius: 10px;
    background-color: aliceblue;
    align-items: center;
    margin-left: 0px;
    margin-right: 15px;
    padding-left: 12%;
    margin-bottom: 50px;
}
.am{
    border-radius: 10px;
    width: 25%;
    align-items: center;
    margin-top: 25px;
    margin-right: 15px;
    padding-left: 25px;
    padding: 20px;
    padding-left: 25px;
}