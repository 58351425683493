table {
    width: 100%;
    border-collapse: collapse;
    
    
}

th, td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
    font-size: 13.5px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

th {
    background-color: #909c9e;
}
tr {
  background-color: rgba(182, 161, 190, 0.993);  
}
.back{
    background-color: rgb(125, 146, 155);
 margin: 3px;   
}