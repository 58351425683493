/* src/components/CourseDisplay.css */

.course-display {
    max-width: 800px;
    background-color: #e6e7e7;
    margin: 0 auto;
    padding: 20px;
    display: block;
    text-align: center;
    
  }
  
  .loading {
    text-align: center;
    font-size: 20px;
    color: #555;
  }
  h3{
    font-size: 30px;
  }
  h2{
    
    font-size: large;
    
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  .course-item {
    background: #fff;
    margin: 10px 0;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(3, 3, 3, 0.1);
    display: flex;
    
    flex-direction: column;
    align-items: flex; /* Align items to the start */
  }
  
  .course-image {
    max-width:100%; /* Make the image responsive */
    height: 100%;
    margin-top: 5px;
    
  }
  
  /* Media Queries for Responsiveness */
  @media (min-width: 600px) {
    .course-item {
      flex-direction: row; /* Display items in a row on larger screens */
      align-items: center; /* Center items vertically */
    }
   
    
    .course-image {
      max-width: 100%; /* Set a fixed width for the image on larger screens */
      margin-right: 15px; /* Space between image and text */
    }
  
    .course-item h2 {
      font-size: 0.5em; /* Increase title size */
      color:black;
      margin-top: -20%;
    }
    .crs{
      width:auto;
      background-color: #555;
    }
    .clean{
      width: 250px;
    }
    .clean1{
      width: 100px;
    }
  }
  
  @media (min-width: 900px) {
    .course-item {
      padding: 20px; /* Increase padding on larger screens */
    }
    .price{
      margin-top: 25%;
    }
    .course-item h2 {
      font-size: 2em; /* Further increase title size */
      
    }
  
    .course-image {
      max-width: 100%; /* Adjust image size for larger screens */
    }
    
  }
  .course-image{
    width: 100%;
  }
.buy{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: large;
  background-color: #f30909;
  color: white;
  border-end-end-radius: 4px;
  border-start-start-radius: 4px;
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 5px;
  
}
.bolding{
  color: rgb(248, 6, 18);
  font-family:monospace;
  font-size: 20px;
  margin-top: 30px;
  text-decoration: dotted;
}
.note{
  color: #f30909;
  margin-top: 25px;
}
.onboard_btn{
  margin-top: 10px;
  font-family:'Times New Roman', Times, serif;
  color: #f3f5f7fb;
 font-size: 15px;
padding: 10px 20px;
cursor: pointer;
}
.byiimag{
  width: 100%;

}
.upl{
  background-color: #e9e5e5;
  border-radius: 8px;
}
.clean{
  width: 290px;
  height: 450px;
}
.clean1{
  width: 300px;
  
}
.co_name{
  margin-top: 10px;
  margin-bottom: 8px;
}
.co_name1{
  margin-top: 10px;
  margin-bottom: 8px;
}