


.carousel{
    width: auto;
    background-color:gainsboro;
    margin-left: 20px;
    border-radius: 10px;
}
h1{
    text-align: center;
}
.paragraph{
    font-family:cursive;
    font-size: 30px;
    color: black;
    text-align: center;
}


img{
    border-radius: 15px;
    
}


.jam{
    font-family: cursive;
    text-align: center;
    padding-top: 5px;
}
.per{
    font-family:Georgia, 'Times New Roman', Times, serif;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 30px;
}
.cr1{
    background-color: rgb(206, 203, 200);
}
.faq{
    margin-top: 40px;
     box-shadow: inset;
     padding: 15px;
    background-color: rgb(228, 229, 230);
    margin-left: 25px;
    border-radius: 10px;
}
.fa{
    text-align: center;
    color: blueviolet;
}
.under{
    margin: 20px;
}
.q1{
    margin-top: 20px;
}
.q2{
    margin-top: 30px;
}
.q3{
    margin-top: 20px;
}
.q4{
    margin-top: 20px;
}
.about{
    width:300px;
    height: 651px;
    margin-left: 12px;
    margin-top: 60px;
}
.camel{
    background-color: rgb(235, 230, 225);
    margin-top: 50px;
    padding-bottom: 50px;
    margin-left: 20px;
    border-radius: 10px;
}
.abdt{
    text-align: center;
    font-size: 25px;
    margin-top: 10px;
}
.nmr{
    font-family: cursive;
    text-align: center;
    font-size: 22px;
    margin-top: 5px;
}
.priyem{
    font-family: 'Times New Roman', Times, serif;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 25px;
    text-align: justify;
}