.section_padding{
    padding: 1rem 1rem;
}

.footer{
    background-color: #0d4752;
    border-radius: 10px;
    margin-bottom:0px ;
    margin-left: 2px;
    margin-right: 2px;
}

.sb_footer{
    display: flex;
    flex-direction: column;
}

.sb_footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 0.1rem;
}

.sb_footer-links_div{
    width: 150px;
    margin: 1rem;
    display:flex;
    flex-direction: column;
    color: white;
}

a{
    color: rgb(175,175,179);
    text-decoration: none;
}
.sb_footer-links_div h4{
    font-size: 14px;
    color: white;
    line-height: 17px;
    margin-bottom: 0.5rem;
}

.sb_footer-links_div p{
    font-size: 13px;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 15px;
    color: burlywood;
    margin: 0.5rem 0;
    cursor:pointer;
}

.sb_footer-below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}

.sb_footer-below-links{
    display: flex;
    flex-direction: row;
}

.sb_footer-below-links p{
    font-size: 13px;
    line-height: 15px;
    margin-left: 2rem;
    color:white;
    font-weight: 600;
}

hr{
    color: white important;
    width: 100%;
}

.sb_footer-copyright p{
    font-size: 13px;
    line-height: 15px;
    color: rgb(255,255,255);
    font-weight: 600;
}

@media screen and (max-width: 850px) {
    .sb_footer-heading h4{
        font-size: 44px;
        line-height: 50px;
    }

}
@media screen and (max-width:550px) {
    .sb_footer-heading h4{
        font-size: 34px;
        line-height: 42px;
    }

    .sb_footer-links div{
        margin: 1rem 0;
    }

    .sb_footer-btn p{
        font-size: 14px;
        line-height: 20px;
    }

    .sb_footer-below{
        flex-direction: column;
        justify-content: left;
    }
    
    .sb_footer-below-links{
        flex-direction: column;
    }

    .sb_footer-below-links p{
        margin-left: 0rem;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 400px){
    .sb_footer-heading h1{
        font-size: 30px;
        line-height: 38px;
    }
}
.company{
    color:blueviolet;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.logo1{
    
    border-radius: 50%;
    background-color:white;
    padding: 2px;
    padding-left: 100px;
    width: 100px;
    height: 100px;
    margin-right: 500px;
    
}
.lgrs{
width: 140px;
height: 115px;
margin-left: -117px;
margin-top: 150%;
}

.handle{
    color: rgb(248, 247, 247);
    font-family: 'Courier New', Courier, monospace;
}