

:root {
  --primary-color: #0b3441;
  --secondary-color: rgb(81, 108, 119);
  --extra-light: #e9ebec;
  
  --gradient-1: linear-gradient(to bottom right, #e6e7e7, rgb(238, 235, 235));
  --gradient-2: linear-gradient(
    to bottom,
    var(--primary-color),
    var(--secondary-color)
  );
  
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
 /* section container Why choose us <p> */
.section__container {
  max-width: var(--max-width);
  margin: auto;
  padding:auto;
  background-color: #e6e7e7;
  text-align: center;
  margin-left: 10px;
  
  
}

 /* section header Why choose us */
.section__header {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 1000;
  text-align: center;
  
}

.section__description {
  max-width: 800px;
  margin-inline: auto;
  color: var(--extra-light);
  text-align: center;
  line-height: 1.75rem;
}


.btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--white);
  background-image: var(--gradient-2);
  outline: none;
  align-self: center;
  border: none;
  border-radius: 5rem;
  cursor: pointer;
  box-shadow: 5px 5px 10px rgba(170, 212, 146, 0.2);
}



a {
  text-decoration: none;
}

.logo {
  display: inline-block;
  padding: 10px 14px;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--white);
  background-image: var(--gradient-2);
  border-radius: 100%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

html,
body {
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
  color: var(--white);
  background-image: var(--gradient-1);
}

nav {
  position: fixed;
  isolation: isolate;
  top: 0;
  width: 100%;
  max-width: var(--max-width);
  margin: auto;
  z-index: 9;
}

.nav__bar {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  background-image: var(--gradient-1);
}

.nav__logo {
  padding: 9px 12px;
  font-size: 1.2rem;
}

.nav__bar a {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--white);
}

.nav__menu__btn {
  font-size: 1.5rem;
  color: var(--white);
  cursor: pointer;
}



.nav__links.open {
  transform: translateY(0);
}

.nav__links a {
  color: var(--white);
  transition: 0.3s;
}

.nav__links a:hover {
  color: var(--white);
}

.btn__large {
  display: none;
}

.header__container {
  padding-top: 8rem;
  display: grid;
  gap: 2rem;
  isolation: isolate;
}

.header__content {
  text-align: center;
}

.header__content h1 {
  margin-bottom: 1rem;
  font-size: 25rem;
  
  text-align:unset;
}
  

.header__content .section__description {
  margin-bottom: 2rem;
  
}

.header__image {
  position: relative;
  isolation: isolate;
  z-index: -1;
}

.header__image img {
  max-width: 400px;
  margin-inline: auto;
  border-radius: 25rem;
}

.header__image::after {
  position: absolute;
  content: "BHARMAL";
  letter-spacing: 2.5rem;
  opacity: 1.5;
  right: 1rem;
  top: 50%;
  transform: translate(50%, -50%) rotate(90deg);
}

.about__container {
  display: grid;
  gap: -80rem;
}


.header__img {
  border-radius: 50%;
}

.header__bg-1 {
  position: absolute;
  max-width: 700px;
  z-index: -5;
}

.about__bg-1 {
  top: 0;
  left: 0;
}

.about__bg-2 {
  right: 0;
  bottom: 0;
}

.about__content {
  text-align: center;
}

.about__content .section__description {
  margin-bottom: 2rem;
}


/* Vivo Phone view  changes for display two card in one row*/
.service__grid {
  margin-top: 2rem;
  display: grid;
  gap: 10px;
  
    grid-template-columns: repeat(2, 1fr);
  }


.service__card {
  padding: 2rem 1rem;
  text-align: center;
  background-image: var(--gradient-2);
  border-radius: 1rem;
  box-shadow: 5px 5px 10px rgb()
}


.service__card span {
  display: inline-block;
  margin-bottom: 1rem;
  font-size: 2rem;
}

.service__card h4 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: fantasy;
  color:antiquewhite;
}

.service__card p {
  color: var(--extra-light);
  line-height: 5rem;
  margin-bottom: 50px;
}

.portfolio__grid {
  margin-top: 3rem;
  display: grid;
  gap: 5rem;
  padding-left: 3rem;
  padding-right: 6rem;
}

.portfolio__card {
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.portfolio__card img {
  transition: 0.5s;
}

.portfolio__card:hover img {
  transform: scale(1.1);
}

.portfolio__banner {
  margin-top: 2rem;
  padding: 2rem;
  display: grid;
  gap: 2rem;
  text-align: center;
  background-image: var(--gradient-2);
  border-radius: 1rem;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.portfolio__banner__card span {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}

.portfolio__banner__card h4 {
  font-size: 1.2rem;
  font-weight: 600;
}

.portfolio__banner__card p {
  font-weight: 500;
}

.contact__container {
  text-align: center;
}

.contact__container .logo {
  margin-bottom: 1rem;
}

.contact__socials {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.contact__socials a {
  padding: 7px 10px;
  font-size: 1.5rem;
  color: var(--white);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.contact__socials a:hover {
  background: var(--gradient-2);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}



@media (width > 576px) {
  .service__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .portfolio__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .portfolio__banner {
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
  }
  .portfolio__pages {
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
  }
}

@media (width > 768px) {
  nav {
    padding: 2rem 1rem;
    position: static;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav__bar {
    padding: 0;
    background-image: none;
  }
  .service__grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .nav__menu__btn {
    display: none;
  }

  .nav__links {
    padding: 0;
    width: unset;
    position: static;
    transform: none;
    flex-direction: row;
    background-image: none;
  }

  .nav__links a:hover {
    color: var(--secondary-color);
  }

  .nav__links li:last-child {
    display: none;
  }

  .btn__large {
    display: flex;
  }

  .header__container {
    padding-top: 5rem;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
  }

  .header__image {
    
    grid-area: 1/3/2/5;
  }

  .header__image img {
    margin-inline-start: unset;
  }

  .header__content {
    grid-area: 1/1/2/4;
    text-align: left;
  }

  .header__content h1 {
    font-size: 6rem;
    line-height: 6rem;
    text-align: left;
  }

  .header__content .section__description {
    text-align: left;
    margin-inline-start: unset;
  }

  
  .about__content,
  .about__content :is(.section__header, .section__description) {
    text-align: left;
  }

  .service__grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .portfolio__container :is(.section__header, .section__description) {
    text-align: left;
    margin-inline-start: unset;
  }

  .portfolio__grid {
    grid-template-columns: repeat(2, 1fr);

  }

  .portfolio__banner {
    grid-template-columns: repeat(3, 1fr);
  }

 
  
  
}
footer h1{
  padding-bottom: 0.5%;
  
  
}

@media (width > 576px) {
  .service {
    grid-template-columns: repeat(1, 1fr);
    margin: 10px;
  }
  .service_lp{
    margin-top: 2rem;
    display: grid;
    gap: 10px;
    
      grid-template-columns: repeat(3, 1fr);
    }
  

}