/* src/EarningsDashboard.css */
.dashboard {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    text-align: center;
    margin-top: 250%;
    background-color: rgb(238, 232, 232);
  }
  
 
  
  
  .heading_dash{
    margin-top: 0px;
    margin-bottom: 30px;
    background-color: #f9fdfd;
    color: rgb(2, 2, 2);
    border-radius: 5px;
}
.img{
    border-radius: 50%;

        background-color: #191b1b;
        margin-top: 20px;
        margin-left: -20px;
        max-width: 140px;
        max-height: 120px;
}
.pack {
        
    margin-left: 0px;
    margin-top:auto;
    color: #ece6e6;
    font-family: 'Times New Roman', Times, serif;
    
    
      }

.carding{
        background-color: #f3f6f7;
        max-width: 300px;
        margin-top: 50px;
        padding: 100px;
        border-start-start-radius: 10px;
        border-end-end-radius: 10px;
    }      
  @media (max-width: 768px) {
    .dashboard{
      margin-top: 0px;
    }
    h1 {
      font-size: 2rem;
    }
  
    .pack {
        margin-left: 92px;
        margin-top: -28px;
        margin-bottom: 7px;
        padding: 10px;
        border-radius: 5px;
        color: #705f5f;
        font-family: 'Times New Roman', Times, serif;
        font-size: 15px;
        
        
          }
     .pack1{
      border-radius: 5px;
      text-align: start;
      background-color:white;
     }
     .grt{
      margin-left: 20px;
      font-family: 'Times New Roman', Times, serif;
      font-size: 17px;
     }     
    
    .heading_dash{
      
        margin-top:-15px;
        margin-bottom: 20px;
        background-color: #f5f8f8;
        color: black;
        padding: 3px;
        border-radius: 5px;
        font-size: 20px;
    }
    .earnimag{
      position:absolute;
      background-color: white;
      width: 80px;
      height: 80px;
          margin-bottom: 0px;
          margin-left: -40px;
          margin-right:auto;
          border-radius: 50%;
          margin-top:-90px;
          padding:0px;
    }
    .carding{
        background-color: white;
        max-width: 350px;
        margin-top: 1px;
        padding: 100px;
        border-start-start-radius: 10px;
        border-end-end-radius: 10px;
    }
    .ex{
      background-color: #e27739;
      margin-top: 0px;
      padding: 1px;
      padding-left: 290px;
      margin-left: 0px;
    }
    .hony{
      margin-left: -560px;
      
    }
    .subex{
      background-color: #faf0e9;
      padding: 30px;
    }
   .bux{
    border: 2px solid #e27739;
    width: 300px;
    margin-left: -90px;
    margin-top: 20px;
    border-radius: 7px;
   }
   .inside{
    margin-top: -20px;
    text-align: start;
   }
   .bux1{
    border: 2px solid #0a7179;
    width: 300px;
    margin-left: -90px;
    margin-top: 20px;
    border-radius: 7px;
   }
   .ex1{
    background-color: #0a7179;
    margin-top: 0px;
    padding: 1px;
    padding-left: 290px;
    margin-left: 0px;
   }
   .hony1{
    margin-left: -560px;
  }
  .subex1{
    background-color: #9cf1f7;
    padding: 30px;
  }
  .inside1{
    margin-top: -20px;
    text-align: start;
   }
   .bux2{
    border: 2px solid rgb(172, 121, 214);
    width: 300px;
    margin-left: -90px;
    margin-top: 20px;
    border-radius: 7px;
   }
   .ex2{
    background-color:rgb(172, 121, 214);
    margin-top: 0px;
    padding: 1px;
    padding-left: 290px;
    margin-left: 0px;
   }
   .hony2{
    margin-left: -560px;
  }
  .subex2{
    background-color: rgb(235, 220, 247);
    padding: 30px;
    
  }
  .inside2{
    margin-top: -20px;
    text-align: start;
   }
   .bux3{
    border: 2px solid violet;
    border-radius: 7px;
    width: 300px;
    margin-left: -90px;
    margin-top: 20px;
   }
   .ex3{
    background-color:violet;
    margin-top: 0px;
    padding: 1px;
    padding-left: 290px;
    margin-left: 0px;
   }
   .hony3{
    margin-left: -560px;
  }
  .subex3{
    background-color: rgb(247, 209, 247);
    padding: 30px;
  }
  .inside3{
    margin-top: -20px;
    text-align: start;
   }
   .team{
    margin-top: 15px;
    background-color: white;
    border-radius: 5px;
    padding-bottom: 250px;
   }
   .team_head{
    padding: 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: rgb(32, 129, 124);
    color: white;
   }
   .icon_size{
    margin-left: 5px;
   }
   .shade{
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-size: 13px;
    color: #030303;
   }
   
  }
  