.admin-container {
    padding: 20px;
}

.fetch-button, .search-button {
    padding: 10px 15px;
    margin: 10px 5px;
    background-color: rgb(19, 19, 17);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.fetch-button:disabled, .search-button:disabled {
    background-color: #cccccc;
}

.error-message {
    color: red;
    margin-top: 10px;
}

.search-container {
    margin-top: 10px;
}

.search-input {
    padding: 10px;
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px; /* Adjust width as needed */
}

.documents-list {
    margin-top: 20px;
}

.document-item {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.document-item hr {
    margin: 10px 0;
    border: 0;
    border-top: 1px solid #ccc;
}
.data{
    text-align: center;
    margin-bottom: 40px;
    background-color: #cccccc;
    border-radius: 3px;
    font-family:monospace;
    padding: 7px;

}
