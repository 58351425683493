.crc1{
    margin: 35px;
    
}
.crc2{
    margin: 35px;
    padding: 15px;
}
.crc3{
    margin: 35px;
}
.crc4{
    margin: 35px;
}
.crc5{
    margin: 35px;
}
.crc6{
    margin: 35px;
}